<template>
  <div style="width: 100%">
    <!-- <div
      :class="[
        'header-box',
        $route.path !== '/home' ? 'header-box-active' : '',
        isFixed ? 'header-fixed header-box-active' : '',
      ]"
    > -->
    <div :class="[
      'header-box',
      $route.path !== '/home' ? 'header-box-active' : '',
      'header-fixed',
      isFixed ? 'header-box-active' : '',
    ]">
      <div class="container">
        <div class="header-left">
          <img @click="goToHome" class="cursor-pointer" src="../../fonts/logo.svg" alt="" />
        </div>
        <div class="header-right">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#1772f7"
            text-color="#373e4f" @select="handleSelect">
            <el-menu-item index="0">首页</el-menu-item>
            <!-- <el-menu-item index="-1">链接宝贝</el-menu-item>
            <el-menu-item index="5">快现形</el-menu-item> -->
            <el-menu-item index="-1">防测评封号
              <img src="@/assets/common/hot.png" class="new-icon" />
            </el-menu-item>
            <el-menu-item index="5">测评查号
              <img src="@/assets/common/hot.png" class="new-icon" />
            </el-menu-item>
            
            <el-menu-item index="1">项目价值</el-menu-item>
            <!-- <el-menu-item index="2">客户评价</el-menu-item> -->
            <el-menu-item index="3">FAQ</el-menu-item>
            <el-menu-item index="4">关于我们</el-menu-item>
            <!-- 
            <el-menu-item></el-menu-item>
            <el-menu-item></el-menu-item>
            <el-menu-item></el-menu-item>
            <el-menu-item></el-menu-item>
            -->
            <!-- <img class="icon_new" src="@/assets/icons/icon-new.gif" alt="" />
            <img class="icon_new2" src="@/assets/icons/icon-new.gif" alt="" /> -->
          </el-menu>
          <div class="header-tap-btn">
            <el-button class="recharge-btn btn" @click="toRecharge" v-if="isLogin">充值
              <img class="icon-gift" src="@/assets/activity/礼标签.png" />
            </el-button>
            <el-button class="inviter-btn btn" @click="tapInvite" v-if="isLogin">邀请有礼</el-button>
            <el-button class="login-btn btn" v-if="!isLogin" @click="tapLogin">微信扫码登录</el-button>
            <div v-else class="user-info" @dblclick="$router.push('/user-info')">
              <el-dropdown @command="handleCommand" placement="bottom-start">
                <span class="el-dropdown-link">
                  <img class="icon-user-head" :src="userPicture" alt="" v-if="userPicture" />
                  <img class="icon-user-head" src="../../assets/home/user-head.png" alt="" v-else />
                  <span class="my-user">我的账户</span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="/user-info">
                    <i class="icon icon-user-info"></i>
                    <span>个人信息</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="/recharge-record">
                    <i class="icon icon-recharge-record"></i>
                    <span>充值记录</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="/use-record">
                    <i class="icon icon-use-record"></i>
                    <span>消耗记录</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item command="/query-record">
                    <i class="icon icon-query"></i>
                    <span>查询记录</span>
                  </el-dropdown-item> -->
                  <el-dropdown-item command="/invite-record">
                    <i class="icon icon-invite-record"></i>
                    <span>邀请记录</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="quit">
                    <i class="icon icon-close"></i>
                    <span>退出登录</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 微信登录 -->
    <el-dialog title="微信登录" :visible.sync="loginDV" width="500px" :before-close="handleClose"
      :close-on-click-modal="false" class="login-DV">
      <div class="login-DV-content">
        <div id="qrCodeImg" ref="qrCodeImg"></div>
        <div class="qrcode" ref="qrCodeUrl" style="display: none" v-if="loginDV"></div>
        <!-- <span>微信扫描二维码登录<span class="block">并关注</span></span> -->
        <span>微信扫码登录，首次扫码<span class="red">关注暗度公众号</span>完成注册</span>
        <div class="lose-efficacy" v-if="loseCode">
          <div class="icon">!</div>
          <div>当前二维码已过期</div>
          <el-button class="login-btn btn" v-if="!isLogin" @click="loginQrCode(0)">刷新二维码</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <div class="tip">
          由于注册人数多，如果页面响应慢或卡顿，请刷新页面重试
          或刷新后右下角联系客户经理
        </div> -->
        <div>
          登录成功即代表同意<span @click="tapUseProtocol">《用户协议》</span><span @click="tapPrivacyProtocol">《隐私权政策》</span>
        </div>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="authDV" width="500px" :close-on-click-modal="false" :before-close="closeAuthDV"
      class="auth-DV">
      <div slot="title">微信扫码获取使用权限</div>
      <div id="qrCodeImg2" ref="qrCodeImg2"></div>
      <div class="qrcode" ref="qrCodeUrl2" style="display: none" v-if="authDV"></div>
      <span slot="footer" class="dialog-footer">
        <div>
          请使用同一微信扫码添加客户经理 添加后请勿删除，否则会影响产品使用
        </div>
      </span>
    </el-dialog>
    <use-protocol :useProtocolDV="useProtocolDV" @closeUseProtocol="closeUseProtocol" />
    <privacy-protocol :privacyProtocolDV="privacyProtocolDV" @closePrivacyProtocol="closePrivacyProtocol" />
    <invite-activity :inviteDV="inviteDV" @closeInviteDV="closeInviteDV" />
    <tips-guide v-if="isShowGuide" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {
  loginQrCode,
  loginQrCodeStatus,
  ticketLogin,
  logout,
} from "@/api/user.js";
import { getItem, setItem, removeItem } from "@/utils/storage.js";
import { mapMutations } from "vuex";
import useProtocol from "./components/use-protocol.vue";
import privacyProtocol from "./components/privacy-protocol.vue";
import inviteActivity from "./components/invite-activity.vue";
import TipsGuide from "@/components/TipsGuide.vue";
export default {
  name: "headers",
  components: {
    TipsGuide,
    privacyProtocol,
    useProtocol,
    inviteActivity,
  },
  props: {},
  data() {
    return {
      isShowGuide: false,
      activeIndex: "0",
      loginDV: false,
      ticket: "",
      qrCodeUrl: "",
      isLogin: false,
      token: {},
      isFixed: false,
      userInfo: false,
      useProtocolDV: false,
      privacyProtocolDV: false,
      inviteDV: false,
      loseCode: false,
      queryCodeId: null,
      userPicture: "",
      scene: "",
      authDV: false,
      qrCodeUrl2: "",
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        this.activeIndex = "" + (getItem("activeIndex") || 0);
        removeItem("activeIndex");
        if (this.$route.path !== "/home") {
          this.activeIndex = "";
        } else {
          this.$emit("to-position", {
            index: +this.activeIndex,
            val: "1",
          });
        }
        if (this.$route.path == "/kxx") {
          this.activeIndex = "5";
        }
      },
      immediate: true,
    },
  },
  created() {
    this.token = getItem("token");
    this.userPicture =
      (getItem("token") && getItem("token").headImageUrl) || "";
    if (this.token) {
      if (+new Date() - this.token.endDate < 0) {
        this.isLogin = true;
      } else {
        removeItem("token");
        this.isLogin = false;
        this.token = false;
      }
    }
    if (getItem("fqa")) {
      this.activeIndex = getItem("fqa") + "";
      removeItem("fqa");
    }
    if (getItem("appraise")) {
      this.activeIndex = getItem("appraise") + "";
      removeItem("appraise");
    }
    if (this.$route.query.zbuc) {
      this.scene = this.$route.query.zbuc;
    }
  },
  mounted() {
    this.$eventBus.$on("openLoginDV", () => {
      this.loginQrCode();
    });
    window.addEventListener("scroll", this.pageScroll);
    this.$eventBus.$on("openActivity", () => {
      this.inviteDV = true;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.pageScroll);
  },
  methods: {
    goToHome() {
      this.$router.push("/home");
      this.handleSelect("0");
    },
    pageScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // if (this.$route.path !== '/home') {
      //   this.isFixed = scrollTop > 80 ? true : false
      // } else {
      //   this.isFixed = scrollTop > 80 ? true : false
      // }
      this.isFixed = scrollTop > 80 ? true : false;
    },
    handleSelect(index) {
      this.activeIndex = index;
      if (index == 3) {
        setItem("fqa", index);
        this.$router.push("/fqa");
        return;
      }
      if (index == 2) {
        setItem("appraise", index);
        this.$router.push("/appraise");
        return;
      }
      if (index == 5) {
        /*
        setItem("Kxx", index);
        this.$router.push("/kxx");
        */
        window.open('https://kuaixianxing.com/', '_blank')
        return;
      }
      if (index == 4) {
        setItem("regard", index);
        this.$router.push("/regard");
        return;
      }
      if (index === "-1") {
        /*
        window.location.href = "https://www.lianjiebaobei.com/";
        */
        window.open('https://www.lianjiebaobei.com/', '_blank')
        return
      }
      if (this.$route.path === "/home") {
        this.$emit("to-position", {
          index: +index,
          val: "0",
        });
      } else {
        this.$router.push("/home");
        setItem("activeIndex", index);
      }
    },
    loginQrCode(type) {
      clearTimeout(this.queryCodeId);
      if (type == 0) this.loseCode = false;
      loginQrCode({
        scene: this.scene,
      }).then((res) => {
        if (res.code === 200) {
          this.qrCodeUrl = res.data.url;
          this.ticket = res.data.ticket;
          this.loginDV = true;
          document
            .querySelector(".login-DV")
            .addEventListener("click", this.tapModel.bind());
          document.querySelector(".el-dialog").style.border = "";
          this.creatQrCode("qrCodeUrl", this.qrCodeUrl, "#qrCodeImg");
          this.loginQrCodeStatus();
          this.queryCodeId = setTimeout(() => {
            this.loseCode = true;
            clearInterval(this.timeId);
          }, 60000);
        }
      });
    },
    // 轮询查是否登录
    loginQrCodeStatus() {
      this.timeId = setInterval(() => {
        loginQrCodeStatus({
          ticket: this.ticket,
        }).then((res) => {
          if (res.code === 200) {
            if (res.data) {
              clearInterval(this.timeId);
              ticketLogin({
                ticket: this.ticket,
              }).then((res2) => {
                if (res2.code === 200) {
                  this.$message({
                    type: "success",
                    message: "登录成功",
                    duration: 2000,
                    offset: 100,
                  });
                  clearTimeout(this.queryCodeId);
                  this.SETTOKEN(res2.data);
                  this.token = getItem("token");
                  this.isLogin = true;
                  this.loginDV = false;
                  this.userPicture = res2.data.headImageUrl;
                  if (res2.data.newUser) {
                    this.isShowGuide = true;
                    // setTimeout(() => {
                    //   this.qrCodeUrl2 = res2.data.qyCustomerQrCodeUrl
                    //   this.authDV = true
                    //   this.creatQrCode('qrCodeUrl2', this.qrCodeUrl2, '#qrCodeImg2')
                    // }, 2000)
                  }
                  if (
                    this.$route.query.redirect &&
                    this.$route.query.redirect !== "/home"
                  ) {
                    this.$router.push(this.$route.query.redirect);
                  }
                }
              });
            }
          }
        });
      }, 3000);
    },
    tapLogin() {
      this.loginQrCode();
    },
    handleClose() {
      this.loginDV = false;
      this.loseCode = false;
      document
        .querySelector(".login-DV")
        .removeEventListener("click", this.tapModel);
      clearInterval(this.timeId);
      clearTimeout(this.queryCodeId);
    },
    creatQrCode(dom, val, imgId) {
      this.$nextTick(() => {
        this.$refs[dom].innerHTML = "";
        new QRCode(this.$refs[dom], {
          text: val, // 需要转换为二维码的内容
          width: 260,
          height: 260,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });

        if (imgId) {
          let myCanvas = this.$refs[dom].querySelector("canvas");
          let img = this.convertCanvasToImage(myCanvas);
          let code = document.querySelector(imgId);
          code.innerHTML = "";
          code.appendChild(img);
        }
      });
    },
    convertCanvasToImage(canvas) {
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      return image;
    },
    async handleCommand(val) {
      if (val === "quit") {
        try {
          await logout();
        } catch (err) {
          console.log(err);
        }
        removeItem("token");
        var date = new Date();
        date.setTime(date.getTime() - 10000);
        document.cookie = "fst=a; expires=" + date.toGMTString();
        if (this.$route.path !== "/home") {
          this.$router.replace("/home");
        } else {
          location.reload();
        }
      } else {
        this.$router.push(val);
      }
    },
    toRecharge() {
      if (this.token) {
        this.$router.push("/recharge");
      } else {
        this.tapLogin();
      }
    },
    ...mapMutations(["SETTOKEN"]),
    tapModel(e) {
      if (e.target == document.querySelector(".login-DV")) {
        document.querySelector(".el-dialog").style.border = "2px solid #015bff";
      }
    },
    tapUseProtocol() {
      this.useProtocolDV = true;
    },
    tapInvite() {
      if (this.token) {
        // this.inviteDV = true
        this.$router.push("/invite-activity");
      } else {
        this.tapLogin();
      }
    },
    closeUseProtocol() {
      this.useProtocolDV = false;
    },
    tapPrivacyProtocol() {
      this.privacyProtocolDV = true;
    },
    closePrivacyProtocol() {
      this.privacyProtocolDV = false;
    },
    closeInviteDV() {
      this.inviteDV = false;
    },
    closeAuthDV() {
      this.authDV = false;
    },
  },
};
</script>

<style scoped lang="scss">
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  width: 100vw;
  padding-right: 1vw;
}

.el-menu-demo {
  .el-menu-item {
    position: relative;
    .new-icon {
      position: absolute;
      top: -5px;
      right: -25px;
      width: 46px;
      z-index: 1;
    }
  }
}

.header-box {
  display: flex;
  justify-content: center;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    width: 1300px;
    height: 80px;
  }

  .header-left {
    display: flex;
    align-items: center;
    align-items: center;

    img {
      height: 26px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .el-menu {
      border-bottom: 0;

      .is-active {
        border-bottom: 4px solid #409eff;
        border-radius: 2px;
      }

      .el-menu-item {
        font-size: 20px;
        margin-right: 18px;
      }
    }

    .header-tap-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 30px;
      width: 332px;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 32px;
        color: #fff;
      }

      .recharge-btn {
        position: relative;
        margin-right: 10px;
        border: 1px solid #ff7950;
        background: linear-gradient(0deg, #ff604e, #ffa853);

        .icon-gift {
          position: absolute;
          right: -20px;
          ;
          top: -20px;
          width: 46px;
        }
      }

      .inviter-btn {
        margin-right: 10px;
        border: 1px solid #ffa200;
        color: #ffa200;
      }

      .login-btn {
        color: #015bff;
        font-weight: 400;
        border: 1px solid #015bff;
      }

      .user-info {
        .el-dropdown-link {
          display: flex;
          align-items: center;
        }

        .icon-user-head {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 4px;
        }
      }

      .my-user {
        font-size: 16px;
        font-weight: 400;
        color: #0668f6;
      }
    }
  }
}

.header-box-active {
  box-shadow: 0px 4px 8px 0px rgba(172, 189, 213, 0.4);
}

.login-DV-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .qrcode {
    display: inline-block;
    margin-bottom: 28px;
  }

  span {
    font-size: 14px;
    color: #9197a5;
  }

  .block {
    color: #373e4f;
    font-weight: bold;
  }

  .red {
    color: red;
    font-weight: bold;
  }

  .lose-efficacy {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 280px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    color: #181b23;
    font-weight: bold;
    font-size: 20px;

    .icon {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: #fff;
      box-shadow: 0px 1px 2px 0px #ffffff,
        0px 8px 8px 0px rgba(253, 185, 68, 0.21);
      background: #ffa200;
      opacity: 0.8;
      margin-bottom: 8px;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      color: #fff;
    }

    .login-btn {
      color: #015bff;
      font-weight: 400;
      border: 1px solid #015bff;
      background: linear-gradient(0deg, #0831ff, #3777ff);
      color: #fff;
      margin-top: 18px;
    }
  }
}

.dialog-footer {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  font-size: 14px;

  .tip {
    width: 367px;
    color: #fdb944;
    text-align: center;
    margin-bottom: 30px;
  }

  span {
    cursor: pointer;
    color: #1772f7;
  }
}

.el-dropdown-menu {
  margin-left: -5px !important;

  .icon {
    display: inline-block;
    font-size: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
}

::v-deep.auth-DV {
  .el-dialog__header {
    padding-left: 0;
    padding-right: 0;

    >div {
      text-align: center;
      color: #595959;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
    }
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
}

.icon_new {
  position: relative;
  top: 3px;
  left: -423px;
}

.icon_new2 {
  position: relative;
  top: 3px;
  left: -570px;
}
</style>
