<template>
  <el-dialog
    title="隐私政策"
    :visible.sync="privacyProtocolDV"
    width="700px"
    :before-close="closePrivacyProtocol"
    :close-on-click-modal="false"
    class="privacy-protocol-DV"
  >
    <div class="privacy-protocol-content">
      <h1>隐私政策</h1>
      <h2>特别提示</h2>
      <p class="block">
        本政策适用于您所使用的深圳市<span class="red">带塔</span>云科技有限公司及其关联公司（以下统称“<span class="red">带塔</span>云”）提供的查询服务。<span class="red">带塔</span>云一直非常重视保护客户的个人信息。在您使用<span class="red">带塔</span>云<span class="blur">暗度</span>系统（以下统称“<span class="blur">暗度</span>”）的服务前，请务必仔细阅读并理解本政策，尤其是其中加粗的条款。当您使用<span class="blur">暗度</span>的服务时，或在本政策更新后继续使用<span class="blur">暗度</span>的服务，即视为您已经阅读、理解并同意本政策及其更新的版本，同意我们按照本政策的约定处理您的个人信息。如您不同意本政策的任何条款，您应立即停止使用<span class="blur">暗度</span>的服务。
      </p>
      <div>
        <p>本政策将帮助您了解以下内容：</p>
        <p>1、我们如何收集和使用您的个人信息</p>
        <p>2、我们如何使用Cookie和同类技术</p>
        <p>3、我们如何共享、转让、公开披露您的个人信息</p>
        <p>4、我们如何保护和保存您的个人信息</p>
        <p>5、您的权利</p>
        <p>6、我们如何处理儿童的个人信息</p>
        <p>7、您的个人信息如何在全球范围转移</p>
        <p>8、本政策如何更新</p>
        <p>9、如何联系我们</p>
      </div>
      <h2>一、我们如何收集和使用您的个人信息</h2>
      <div>
        <p>
          <span class="block">个人信息</span
          >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        </p>
        <p>
          <span class="block">个人敏感信息</span
          >是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<span
            class="block"
            >本政策中涉及的个人敏感信息，将采用加粗字体突出显示。</span
          >
        </p>
        <p><span class="blur">暗度</span>仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
        <p class="block">（一）核心业务功能收集和使用您个人信息的情形</p>
        <p class="block">
          我们可能会收集、保存和使用下列与您有关的信息才能实现下述功能。如果您不提供相关信息，您将无法享受相关的<span class="blur">暗度</span>服务。这些功能包括：
        </p>
        <p class="block">1、注册成为会员</p>
        <p>
          为完成创建账号，您需提供以下信息（统称“账户信息”）：您的<span
            class="block"
            >手机号、账号名称</span
          >/昵称、<span class="red">微信号，</span
          >我们将通过发送短信验证码来验证您的身份是否有效；您还可以补充您的生日、性别、地区，您补充的信息将有助于我们为您提供个性化的服务体验，如果您不提供这些补充信息，不会影响您使用<span class="blur">暗度</span>服务的基本功能。
        </p>
        <!-- <h2 class="block">2、为您提供物流服务</h2>
        <p>
          在您下单寄件或您的信息作为收件人被提供时，<span class="blur">暗度</span>或<span class="blur">暗度</span>通过其授权的业务合作伙伴收集您的以下信息：寄件人及收件人的姓名、地址、<span
            class="block"
            >联系方式</span
          >和货件信息（统称“运单信息”），用于提交订单、生成物流运单、收件、分拣、仓储、运输、出口清关、进口清关、以及派送货件；根据相关法律要求，我们还收集您的<span
            class="block"
            >身份证信息</span
          >用于核验您的身份和进出口报关。
        </p> -->
        <p class="block">2、客服与售后</p>
        <p>我们的客服和售后功能会使用您的账号信息和充值信息。</p>
        <p>
          为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的<span
            class="block"
            >账号信息</span
          >核验您的身份。此外，我们还会收集您参与问卷调查时向我们提交的问卷答复信息、您在<span class="blur">暗度</span>网站上提交“合作咨询”所提供的联系人姓名、公司名称、<span class="block">联系电话、邮箱地址</span>及您的咨询信息。
        </p>
        <p class="block">3、改善我们的产品或服务</p>
        <p>
          <span class="block"
            >我们可能会将收集的您的账户信息、运单信息进行数据分析以形成用户画像，向您提供个性化服务、推送您可能感兴趣的商品或服务。</span
          >对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为你提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息、合法间接获取的信息及合法公开渠道获取的信息结合，或者将合法获取的您的可识别个人信息与无法识别信息主体的信息结合，为您提供个性化服务及改善我们的产品和服务。
        </p>
        <p class="block">4、保障交易安全</p>
        <p>
          为提高您使用我们的服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的<span
            class="block"
            >浏览信息、运单信息</span
          >、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“<span
            class="block"
            >URL</span
          >”）；我们也会收集您的设备信息对于<span class="blur">暗度</span>系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
        </p>
        <p class="block">（二）附加业务功能收集和使用您个人信息的情形</p>
        <p>
          为使您更便捷地使用<span class="blur">暗度</span>的服务，我们的以下附加功能可能会收集和使用您的个人信息。如果您不提供这些信息，您依然可以使用<span class="blur">暗度</span>的服务，但您可能无法使用这些可以为您带来便捷的附加功能或者需要重复填写一些信息。
        </p>
        <!-- <p class="block">1.基于摄像头（相机）的附加功能</p>
        <p>您可以使用这个附加功能完成扫码寄件、扫码加入团队的功能。</p>
        <p>
          上述附加功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以在手机等设备的“设置”中逐项查看上述权限的开启状态，并可以决定将这些权限随时开启或关闭。<span
            class="block"
            >您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述功能；如您关闭权限，我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限不会影响此前基于您的授权所进行的个人信息的处理。</span
          >
        </p> -->
        <p class="block">（三）其他用途</p>
        <p>
          1、我们通过技术手段对收集的您的个人信息进行去标识化处理后，去标识化处理的信息将无法识别主体，您知悉并同意，<span
            class="block"
            >我们有权使用已经去标识化的信息。在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span
          >
        </p>
        <p>
          2、我们会对我们服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
        </p>
        <p>
          3、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
        </p>
        <p>
          4、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过在线点击授权或邮件、短信等形式事先征求您的同意。
        </p>
        <p class="block">（四）征得同意的例外</p>
        <p>
          您充分知悉，在以下情形中，我们收集、使用个人信息无须征得您的授权同意：
        </p>
        <p>1、与国家安全、国防安全有关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>
          4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        </p>
        <p>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
        <p>
          6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </p>
        <p>7、根据您的要求签订和履行合同所必需的；</p>
        <p>
          8、用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；
        </p>
        <p>9、为合法的新闻报道所必需的；</p>
        <p>10、法律法规规定的其他情形。</p>
        <p>
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
      </div>
      <h2>二、我们如何使用Cookie和同类技术</h2>
      <div>
        <p class="block">（一）Cookie</p>
        <p>
          为确保网站正常运转，我们会在您的计算机或移动设备上存储名为<span
            class="block"
            >Cookie</span
          >的小数据文件。<span class="block">Cookie</span
          >通常包含标识符、站点名称以及一些号码和字符。借助于<span class="block"
            >Cookie</span
          >，网站能够存储您的偏好等数据。
        </p>
        <p>
          我们不会将<span class="block">Cookie</span
          >用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
          <span class="block">Cookie</span>。有关详情，请参见<span class="block"
            >AboutCookies.org</span
          >。您可以清除计算机上保存的所有<span class="block">Cookie</span
          >，大部分网络浏览器都设有阻止<span class="block">Cookie</span
          >的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
        </p>
        <p class="block">（二）网站信标和像素标签</p>
        <p>
          除<span class="block">Cookie</span
          >外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击<span
            class="block"
            >URL</span
          >。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <p class="block">（三）Do Not Track（请勿追踪）</p>
        <p>
          很多网络浏览器均设有<span class="block">Do Not Track</span
          >功能，该功能可向网站发布<span class="block">Do Not Track</span
          >请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了<span
            class="block"
            >Do Not Track</span
          >，那么我们的所有网站都会尊重您的选择。
        </p>
      </div>
      <h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
      <div>
        <p class="block">（一）共享</p>
        <p>
          1、我们不会与<span class="red">带塔</span>以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
        </p>
        <p>
          （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
        </p>
        <p>
          （2）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
        </p>
        <p>
          （3）在法律法规允许的范围内，为维护<span class="blur">暗度</span>或合作伙伴、您或其他<span class="blur">暗度</span>用户或社会公众利益、财产或安全免遭损害而有必要提供；
        </p>
        <p>
          （4）只有共享您的信息，才能实现我们的服务的核心功能或提供您需要的服务；
        </p>
        <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
        <p>
          （6）符合与您签署的相关协议（包括在线签署的电子协议）或其他的法律文件约定所提供；
        </p>
        <p>（7）基于学术研究而使用；</p>
        <p>（8）基于符合法律法规的社会公共利益而使用。</p>
        <p>
          2、与我们的关联公司共享：您的个人信息可能会与<span class="red">带塔</span>的关联公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p>
        <p>
          我们的关联公司是指：不时控制深圳市<span class="red">带塔</span>云科技有限公司或其实体、或深圳市<span class="red">带塔</span>云科技有限公司或其实体所控制、或与深圳市<span class="red">带塔</span>云科技有限公司或其实体被共同控制之下的任何实体，其中“控制”一词意指直接或间接具备或控制50%以上投票权。
        </p>
        <p>
          3、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。目前，我们的授权合作伙伴包括以下类型：
        </p>
        <p>
          1）供应商、服务提供商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式和潜在风险、广告服务、运输、仓储、清关、末端收派、支付便利、提供客户服务、提供数据存储服务、提供第三方附加业务功能服务（如地图功能）或进行学术研究和调查。
        </p>
        <p>
          我们的关联公司与我们适用同样的数据合规管理制度、本政策以及相关保密和安全措施。对我们与之共享个人信息的我们的关联公司之外的公司、组织和个人，我们会与其签署严格的保密协定/数据处理协议，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <p class="block">（二）转让</p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>
          1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </p>
        <p>
          2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>
          3、符合与您签署的相关协议（包括在线签署的电子协议）或其他的法律文件约定所提供；
        </p>
        <p>
          4、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。
        </p>
        <p class="block">（三）公开披露</p>
        <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1、获得您明确同意后；</p>
        <p>
          2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
      </div>
      <h2>四、我们如何保护和保存您的个人信息</h2>
      <div>
        <p class="block">（一）我们如何保护您的个人信息安全</p>
        <p>
          1、<span class="blur">暗度</span>成立了数据安全管理组织，采取个人信息在物理（门禁隔离、监控系统）、管理（制定信息安全体系制度、将数据分类分级、与员工签订保密协议、开展数据安全和隐私保护培训、定期开展合规自评估）和技术（通过<span
            class="block"
            >https</span
          >安全浏览方式确保传输加密、采用<span class="block">AES256</span
          >加密技术确保数据存储加密、敏感信息内容展示脱敏、匿名化处理、系统访问部署了双因素认证访问控制机制、自动化代码检查、日志审计）等方面的安全保障措施努力保护您的个人信息不被未经授权地访问、使用、披露、修改、损坏或丢失及其它的形式的非法处理。
        </p>
        <p>
          2、我们会定期组织安全应急预案演练，防止安全事件发生。若不幸发生<span class="blur">暗度</span>知悉所获取并储存的数据安全受到危害时，或由于外部行为（例如黑客攻击）使用户的非公开信息被披露给不相关第三方时，尽管本政策中有其他免责规定，<span class="blur">暗度</span>也会启动应急预案，组成紧急应急小组，启动流程，进行内部调查、上报并通知监管部门、以及配合监管部门工作，降低事故对用户的影响，同时会在认为适当的情况下采取推送通知、邮件、公告等形式告知相关用户，告知其被披露的信息以及<span class="blur">暗度</span>对该等情况的知悉程度。
        </p>
        <div class="block">
          <p>（二）个人信息的保存期限</p>
          <p>
            1.您的账户信息及账户内相关权益、虚拟资产信息将在您注销账户后立即删除，不可恢复。
          </p>
          <!-- <p>
            2.为满足您的查询需要，您的订单信息在<span class="blur">暗度</span>将保留三个月，您可通过订单上标明的寄件人、收件人手机号或运单号在<span class="blur">暗度</span>查询货件路由信息，满足该期限后，我们将在<span class="blur">暗度</span>中去除您的运单信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。
          </p> -->
          <p>
            2.如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
          </p>
        </div>
      </div>
      <h2>五、您的权利</h2>
      <div>
        <p>
          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </p>
        <p class="block">（一）访问您的个人信息</p>
        <p>
          您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
        </p>
        <p>
          账户信息——如果您希望访问或编辑您的账户中的个人资料信息，您可以前往网页端-“用户信息”执行此类操作。
        </p>
        <p>
          如您需要访问您在使用我们的产品或服务过程中产生的其他个人信息，您可以与我们联系，我们会根据本政策所列明的方式和期限响应您的请求。
        </p>
        <p class="block">（二）更正您的个人信息</p>
        <p>
          当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式更正您的个人信息。
        </p>
        <p>
          如您需要更正您在使用我们的产品或服务过程中产生的其他个人信息，如修改派送地址、<span
            class="block"
            >联系电话</span
          >，您可以与我们的客服联系。
        </p>
        <p class="block">（三）删除您的个人信息</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规；</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
        <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
        <p>5、如果我们不再为您提供产品或服务。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p>
          当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <p class="block">（四）改变您授权同意的范围</p>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成（见本政策“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
        </p>
        <p>
          您可以通过删除信息、关闭设备功能、在<span class="blur">暗度</span>中进行隐私设置、在您的设备中设置或关闭相关权限（见本政策“第一部分”-“（二）附加业务功能收集和使用您个人信息的情形”）改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
        </p>
        <p>
          当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p>如果您不想接受我们给您发送的商业广告，您随时可通过以下方式取消：</p>
        <p class="block">
          无论您基于何种原因拒绝接收相关信息，您可以在收到营销信息时，根据信息中的退订提示（如“回复TD退订”），要求<span class="blur">暗度</span>不再向您发送营销信息，<span class="blur">暗度</span>会尊重您的请求。
        </p>
        <p class="block">（五）响应您的上述请求</p>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        </p>
        <p>我们将在三十天内做出答复。</p>
        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
        <p>6、涉及商业秘密的。</p>
      </div>
      <h2>六、我们如何处理儿童的个人信息</h2>
      <div>
        <p>
          我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得使用我们的产品、服务。
        </p>
        <p>
          对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
        </p>
        <p>
          尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。
        </p>
        <p>
          如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
        </p>
      </div>
      <h2>七、您的个人信息如何在全球范围转移</h2>
      <div>
        <p>
          原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
        </p>
        <!-- <p>
          <span class="block"
            >如您选择<span class="blur">暗度</span>的国际件服务，您明确知晓您的个人信息将被传输到境外管辖区</span
          >。<span class="blur">暗度</span>国际件在海外采取自运营或合作伙伴代理模式，我们通过系统对接的方式，将国际件<span
            class="block"
            >运单信息</span
          >传输给海外关联公司、合作伙伴。
        </p>
        <p>
          此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，运单信息采取加密传输、定期评估审计接收方的安全能力水平、签订数据处理协议/条款。
        </p> -->
      </div>
      <h2>八、本政策如何更新</h2>
      <div>
        <p>我们的隐私政策可能变更。</p>
        <p>
          未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件或短信发送通知，说明隐私政策的具体变更内容）。
        </p>
        <p>本政策所指的重大变更包括但不限于：</p>
        <p>
          1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p>
          2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        </p>
        <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>
          5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </p>
        <p>6、个人信息安全影响评估报告表明存在高风险时。</p>
        <p>我们还会将本政策的旧版本存档，供您查阅。</p>
      </div>
      <h2>九、如何联系我们</h2>
      <div>
        <p>如果您对本隐私政策有任何疑问、意见或建议，通过联系在线客服。</p>
        <p>一般情况下，我们将在三十天内回复。</p>
        <p>
          如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向网信、电信、公安及工商等监管部门进行投诉或举报。
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'privacyProtocol',
  props: {
    privacyProtocolDV: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {

  },
  methods: {
    closePrivacyProtocol() {
      this.$emit('closePrivacyProtocol')
    }
  }
}
</script>

<style scoped lang="scss">
.privacy-protocol-DV {
  .privacy-protocol-content {
    height: 480px;
    overflow-y: scroll;
    line-height: 28px;
    font-size: 16px;
    h1,
    h2 {
      padding: 8px 0;
    }
    .block {
      color: #000000;
      font-weight: 700;
    }
    .blur {
      color: blue;
    }
    .red {
      color: red;
    }
  }
  ::v-deep.el-dialog__body {
    padding-top: 8px;
  }
}
</style>
