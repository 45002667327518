<template>
  <el-dialog
    title="用户协议"
    :visible.sync="useProtocolDV"
    width="700px"
    :before-close="closeUseProtocol"
    :close-on-click-modal="false"
    class="use-protocol-DV"
  >
    <div class="use-protocol-content">
      <h1>尊敬的用户:</h1>
      <p>
        欢迎使用<span class="blur">暗度</span>（以下简称“<span class="blur">暗度</span>”或“本系统”），为了使您获得安全的服务体验，在此列明以下服务条款，详述了您使用本网站的服务所须遵守的条款和条件，请您在使用<span class="blur">暗度</span>前仔细阅读。
      </p>
      <h1>服务条款：</h1>
      <h2>一、特别申明</h2>
      <div>
        <p>
          1、<span class="blur">暗度</span>是由深圳市<span class="red">带塔</span>云科技有限公司（以下简称“<span class="red">带塔</span>云”）拥有及营运，本系统将完全按照以下发布的服务条款和操作规则严格执行，您必须完全同意所有服务条款，才能享受<span class="blur">暗度</span>所提供的服务。点击"同意"即表示您已完全知悉并接受全部服务条款。
          <span class="red">带塔</span>云有权根据业务需要酌情修订"条款"，并在其系统上予以公告。如用户不同意相关修订，请用户立即停止使用"服务"。经修订的"条款"一经在<span class="blur">暗度</span>公布，即产生效力。如用户继续使用"服务"，则将视为用户已接受经修订的"条款"，当用户与<span class="red">带塔</span>云发生争议时，应以最新的"条款"为准。
          本服务协议内容包括协议正文及所有<span class="red">带塔</span>云已经发布或将发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
        </p>
        <p>
          2、<span class="blur">暗度</span>提供订单打印、订单查询等服务，除此之外与系统服务有关的上网所需设备（包括个人电脑、手机、其它与接入互联网或移动网有关的上网装置）及所需的费用（与此服务相关的电话费、上网费、为使用移动网而支付的手机费等）均应由用户自行承担。
        </p>
      </div>
      <h2>二、用户条款</h2>
      <div>
        <p>1、<span class="blur">暗度</span>允许用户使用有限的服务，具体包括如下：</p>
        <p class="block">
          （1）订单管理：包括订单新建、订单编辑、订单打印等服务功能；
        </p>
        <p class="block">（2）用户信息：提供帐户密码修改等功能。</p>
        <p>
          2、您可按照<span class="blur">暗度</span>的要求，在提交相关资料并经过许可后即可成为<span class="blur">暗度</span>用户。
        </p>
      </div>
      <h2>三、使用规则</h2>
      <div>
        <p>
          1、用户在使用<span class="blur">暗度</span>服务过程中，必须遵循以下规定，否则<span class="red">带塔</span>云有权做出独立判断，立即取消该用户的使用资格并注销其用户帐号：
        </p>
        <div class="block">
          <p>（1）遵守国家法律法规、当地法规及互联网相关规定。</p>
          <p>
            （2）遵守所有与运单及订单有关的协议、规定、程序以及本系统的服务条款。
          </p>
          <p>（3）不使用网络服务作非法用途。</p>
          <p>
            （4）不得利用本系统进行任何可能对网络正常运转造成不利影响的行为。
          </p>
          <p>
            （5）不得利用本系统提供的服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。
          </p>
          <p>
            （6）用户在使用系统所提供的服务时，必须提供准确的资料，如资料有任何变动，必须及时更新。
          </p>
          <p>（7）允许本系统通过电子邮件或其他方式向用户发送相关商业信息。</p>
          <p>（8）不得进行有损本系统利益的行为。</p>
          <p>（9）不得通过盗取帐号、密码，诈骗等不正当手段获得用户信息。</p>
          <p>（10）不得出借、转让用户帐号给他人。</p>
          <p>
            （11）不得以用户帐号当作商品进行交易（如出售、拍卖用户帐号等）。
          </p>
        </div>
        <p>
          2、<span class="blur">暗度</span>针对某些特定的服务的使用通过各种方式（包括但不限于系统公告、电子邮件、手机短信提醒等）做出的任何声明、通知、警示等内容视为本条款的一部分，用户如果使用该服务，视为用户同意该等声明、通知、警示的内容。
        </p>
        <p>3、结束服务：</p>
        <div class="block">
          <p>
            （1）<span class="red">带塔</span>云可随时根据实际情况中断一项或多项系统服务。<span class="blur">暗度</span>不需对任何个人或第三方负责而随时中断服务。如用户对以后的条款修改有异议，或对系统的服务不满，可以停止使用<span class="blur">暗度</span>的系统服务。
          </p>
          <p>
            （2）结束<span class="blur">暗度</span>的服务后，用户使用系统服务的权利马上终止。从结束服务时起，系统没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
          </p>
        </div>
      </div>
      <h2>四、隐私政策</h2>
      <div>
        <p>尊重及保护用户是<span class="blur">暗度</span>的一项基本政策。</p>
        <p>
          1、<span class="red">带塔</span>云保证一定不会在未经您授权的情况下公开、编辑及透漏您的信息，但下列情况除外：
        </p>
        <div class="block">
          <p>（1）获得您的明确授权；</p>
          <p>（2）根据有关的法律法规要求；</p>
          <p>（3）按照相关政府主管部门的要求；</p>
          <p>（4）为维护社会公众的利益；</p>
          <p>（5）为维护顺丰速运的合法权益。</p>
        </div>
        <p>2、用户名和密码</p>
        <p>
          当您成为<span class="blur">暗度</span>的用户并设置密码后，您只能通过密码来使用帐号并管理您的信息，如果泄漏了密码，则可能会丢失信息，并且有可能导致对用户不利的行为。若因为您的密码遗失、泄露、被破解等原因导致您的信息泄露，您应该立即与我们取得联系。
        </p>
        <p>3、第三方</p>
        <p>
          <span class="blur">暗度</span>不会向任何第三方提供、出售、出租、分享和交易您的个人信息、订单信息、运单信息，除非第三方和<span class="blur">暗度</span>一起为网站和用户提供服务并且在该服务结束后会将被禁止访问包括其以前能够访问的所有这些资料。当<span class="blur">暗度</span>被法律强制或依照政府要求提供您的信息时，本网站将善意地披露您的相关资料。
        </p>
        <p>4、信息的存储和交换</p>
        <p>
          您信息和资料被收集和存储在放置于中国的服务器上。只有为了做备份的需要时，<span class="blur">暗度</span>才可能需要将您的资料传送到别国的服务器上。
        </p>
        <p>5、安全</p>
        <p>
          <span class="blur">暗度</span>有相应的安全措施来确保本网站掌握的信息不丢失、不被滥用和编造。这些安全措施包括向其它服务器备份数据和对密码加密。尽管本网站有这些安全措施，但请注意在因特网上不存在"完善的安全措施"。<span class="blur">暗度</span>保留在任何时间拒绝履行任何有疑问或被怀疑有信用欺诈嫌疑的使用请求。
        </p>
      </div>
      <h2>五、知识产权声明</h2>
      <div>
        <p>
          1、<span class="blur">暗度</span>网站内所有内容，包括所有文字、图片、图形、音频、视频等都是本网站财产，均受版权、商标和其它财产所有权法律的保护。未经本网站同意，任何企业或个人均无权复制、下载、传输、再造本网站任何内容，也不得提供给第三者使用，否则应负所有法律责任。违反上述声明而给本公司造成损失的，<span class="red">带塔</span>云将依法追究其法律责任。
        </p>
        <p>
          2、<span class="blur">暗度</span>为提供服务而使用的任何软件（包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse
          engineer）、反向编译（decompile）或反汇编（disassemble）。
        </p>
      </div>
      <h2>六、免责声明</h2>
      <div>
        <p>
          1、用户明确同意使用<span class="blur">暗度</span>服务所存在的风险及产生的一切后果将完全由用户自己承担。
        </p>
        <p>
          2、<span class="blur">暗度</span>不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性、安全性、出错发生均不作担保。
        </p>
        <p>
          3、<span class="blur">暗度</span>有义务在技术上保证本网站的正常运行，而对于因不可抗力或本网站不能控制的原因造成的服务中断或其它缺陷，<span class="blur">暗度</span>不承担任何责任，但本网站会尽力协助处理以减少因此给用户造成的损失和影响。
        </p>
        <p>
          4、由于用户自己将会员密码告知他人、与他人共享注册帐户、转借或出让帐户给他人使用，由此导致的任何信息泄露、直接或间接损失由您自行承担，<span class="blur">暗度</span>及<span class="red">带塔</span>云无需承担任何责任。
        </p>
        <p>
          5、由于用户向<span class="blur">暗度</span>提供错误、不完整、不实信息等造成不能正常使用本网站服务或遭受任何其他损失，<span class="blur">暗度</span>无需承担任何责任。
        </p>
        <p>
          6、<span class="blur">暗度</span>不担保所有由<span class="blur">暗度</span>发出的短信不会发生遗失或未送达。由于短信运营商网络运转不正常，天灾人祸、战争等原因造成网络瘫痪或者接收短信的用户手机网络通道不畅通等不可抗力因素，而导致短信无法即时、准确地送达用户，<span class="blur">暗度</span>及<span class="red">带塔</span>云无须承担相应责任。
        </p>
        <p>
          7、<span class="blur">暗度</span>网站及其内容是在现有资料的基础上提供，本网站并不会因网站内容所引起的任何损失而作直接、间接、意外、衍生性的及惩罚性的损害赔偿
          （包括金钱利益及无形的损失）及负上法律责任（除了法律上已隐含的条例）。
        </p>
        <p class="red">
          8、<span class="blur">暗度</span>会提供其他网站的链接，这些链接的网站并非由<span class="blur">暗度</span>控制，故不对该网站的内容、其链接网站的内容及其内容的更新及转变承担任何责任，这些网站的使用及内容也不代表<span class="blur">暗度</span>的立场。
        </p>
      </div>
      <h2>七、条款修改</h2>
      <div>
        <p>
          1、<span class="blur">暗度</span>有权随时修改本协议的任何条款，一旦本条款的内容发生变动，<span class="blur">暗度</span>将会通过各种适当方法向用户提示修改内容。
        </p>
        <p>
          2、如果不同意<span class="blur">暗度</span>对本协议相关条款所做的修改，用户有权停止使用<span class="blur">暗度</span>所提供的服务。如果用户继续<span class="blur">暗度</span>所提供的服务，则视为用户接受<span class="blur">暗度</span>对本协议相关条款所做的修改。
        </p>
      </div>
      <h2>八、通告</h2>
      <div>
        <p>
          <span class="blur">暗度</span>会通过网页公告或电子邮件或手机短信传送等方式，把通告传送给用户，该等通告于发送之日即视为已送达收件人。
        </p>
      </div>
      <h2>九、违约赔偿及责任限制</h2>
      <div>
        <p>
          1、因用户违反有关法律、法规或本网站的服务条款而令<span class="blur">暗度</span>招致损失，用户同意承担由此造成的损害赔偿责任。
        </p>
        <p>
          2、用户同意<span class="blur">暗度</span>不会为用户因使用本网站而招致的损失负上任何法律责任，也同意放弃追究<span class="blur">暗度</span>任何赔偿。
        </p>
      </div>
      <h2>十、法律监管</h2>
      <div>
        <p>
          1、本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。
        </p>
        <p>
          2、如发生<span class="blur">暗度</span>服务条款与中国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对用户产生法律效力和影响。
        </p>
        <p>
          3、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向深圳市<span class="red">龙华</span>区人民法院提起诉讼。
        </p>
        <!-- <div class="block">
          <p>附：快递服务契约条款</p>
          <p>《快递服务契约条款》</p>
        </div>
        <p>
          1、特别声明：寄件人托运价值超过2万元的贵重物品的，应当在托运时向本公司声明。寄件人未声明的，该物品毁损、灭失后，本公司有权按照不超过2万元的一般物品予以赔偿。
        </p>
        <p>2、为保证货物安全送达，寄件人办理托运时须承担以下义务：</p>
        <div class="block">
          <p>
            （1）如实申报托寄物内容和价值，并准确、清楚地填写寄件人、收件人的名称、地址、联系电话等资料；
          </p>
          <p>
            （2）本公司为集货混装运输，寄件人应根据托寄物的性质（尤其是易碎品），提供充分的防破损措施，保障安全运输。
          </p>
        </div>
        <p>3、关于费用和发票的约定：</p>
        <div class="block">
          <p>
            （1）已经发运的到付快件，如收件人尚未付费，寄件人要求更改为寄件人付费的，需要支付额外服务费。该服务费标准以本公司在官方网站上的公布价格为准。
          </p>
          <p>
            （2）寄件人指示在物流中心、保税区等需要支付出/入仓费或其他额外费用的特殊地址收取或派送快件时，寄件人或其指定付款人应当偿还本公司垫付的上述出/入仓费等额外费用，并向本公司支付附加服务费。附加服务费标准以本公司在官方网站上的公布价格为准。
          </p>
          <p>
            （3）无法派送的托寄物，若寄件人要求退回，则双程费用均由寄件人承担。
          </p>
          <p>
            （4）本公司在向月结客户收取月结款时提供发票；有发票需求的散单客户，请在付款后1个月内持运单原件向本公司索取发票。
          </p>
        </div>
        <p>
          4、若因本公司原因造成托寄物毁损、灭失的，本公司将免除本次运费。若寄件人未选择保价或特安，则本公司对月结客户在不超过运费九倍的限额内、对非月结客户在不超过运费七倍的限额内赔偿托寄物的实际损失。若寄件人已选择保价或特安，则本公司按照投保金额予以赔偿。若托寄物仅有部分损失，则按照损失比例赔偿。
        </p>
        <p>
          5、对于签单返还服务，若因本公司原因导致签收回单毁损、灭失的，本公司将免费提供一次签单返还服务作为赔偿。
        </p>
        <p>
          6、本契约条款未作约定的，或本契约条款与国家相关法律法规及标准相冲突的，按照相关规定执行。
        </p> -->
      </div>
    </div>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
export default {
  name: 'useProtocol',
  components: {},
  props: {
    useProtocolDV: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    closeUseProtocol() {
      this.$emit('closeUseProtocol')
    }
  }
}
</script>

<style scoped lang="scss">
.use-protocol-DV {
  .use-protocol-content{
    height: 480px;
    overflow-y: scroll;
    line-height: 28px;
    font-size: 16px;
    h1,
    h2 {
      padding: 8px 0;
    }
    .block {
      color: #000000;
      font-weight: 700;
    }
    .blur {
      color: blue;
    }
    .red {
      color: red;
    }
  }
  ::v-deep.el-dialog__body {
    padding-top: 8px;
  }
}
</style>
