<template>
  <el-dialog
    title="邀请有礼"
    :visible.sync="inviteDV"
    width="1200px"
    :before-close="closeInviteDV"
    :close-on-click-modal="false"
    class="invite-DV"
  >
    <div class="invite-content">
      <div class="invite-box">
        <div class="activity-content">
          <div class="invite-title">活动内容</div>
          <div class="invite-rules">
            1、即日起，每成功邀请一位亚马逊卖家注册并使用暗度，将获得6次免费查询的机会。
          </div>
          <div class="invite-rules">
            2、邀请非亚马逊卖家恶意骗取奖励将视为违规，暗度有权取消对应账号的活动资格或封号。
          </div>
        </div>
        <div class="invite-method">
          <div class="invite-title">邀请方法</div>
          <div class="invite-rules">
            1. 使用二维码邀请：<span>右键复制（鼠标右键 -> 复制图片）</span
            >下方专属邀请二维码，使用微信转发给您的同事或朋友。
          </div>
          <div class="invite-rules">
            二维码有效期至&nbsp;<span>{{ ticket.expirationDate }}</span>
          </div>
          <div class="qrcode-box">
            <div class="qrcode" ref="qrCodeUrl" v-if="inviteDV"></div>
          </div>
          <div class="invite-rules">
            2. 使用链接邀请：
            <span class="link-url" ref="link">{{ copyVal }}</span>
            <span class="tap-clone" @click="tapCopyLink">复制链接</span>
          </div>
          <div class="table-box">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              :header-cell-style="{ background: '#f6f8fa' }"
              height="180"
            >
              <el-table-column
                prop="promotionDate"
                label="邀请时间"
                fixed
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="被邀请人"
                fixed
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="headImageUrl"
                label="被邀请人头像"
                fixed
                align="center"
              >
                <template slot-scope="{ row }">
                  <img :src="row.headImageUrl" alt="" />
                </template>
              </el-table-column>
              <el-table-column
                prop="description"
                label="使用状态"
                fixed
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getCustomerTicket, invitationalInfo } from '@/api/activity.js'
export default {
  name: 'inviteActivity',
  components: {},
  props: {
    inviteDV: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      params: {
        pageNo: 1,
        pageSize: 1000
      },
      ticket: {},
      qrCodeUrl: '',
      tableData: [
        {
          a: '2021-08-09 08:00:00',
          b: '小白',
          c: '',
          d: '已开始使用（有效申请）'
        },
        {
          a: '2021-08-09 08:00:00',
          b: '小白',
          c: '',
          d: '已开始使用（有效申请）'
        },
        {
          a: '2021-08-09 08:00:00',
          b: '小白',
          c: '',
          d: '已开始使用（有效申请）'
        },
        {
          a: '2021-08-09 08:00:00',
          b: '小白',
          c: '',
          d: '已开始使用（有效申请）'
        },
        {
          a: '2021-08-09 08:00:00',
          b: '小白',
          c: '',
          d: '已开始使用（有效申请）'
        }
      ],
      copyVal: '测试代码233'
    }
  },
  computed: {},
  watch: {
    inviteDV: {
      handler () {
        if (this.inviteDV) {
          this.getCustomerTicket()
          this.invitationalInfo()
        }
      }
    }
  },
  created () {},
  methods: {
    closeInviteDV () {
      this.$emit('closeInviteDV')
    },
    creatQrCode () {
      this.$nextTick(() => {
        this.$refs.qrCodeUrl.innerHTML = ''
        new QRCode(this.$refs.qrCodeUrl, {
          text: this.qrCodeUrl, // 需要转换为二维码的内容
          width: 220,
          height: 220,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    tapCopyLink () {
      window.getSelection().selectAllChildren(this.$refs.link)
      document.execCommand('copy')
      this.$message.success('复制成功')
    },
    getCustomerTicket () {
      getCustomerTicket().then(res => {
        if (res.success) {
          this.ticket = res.data
          this.copyVal =
            process.env.NODE_ENV === 'production'
              ? `https://${location.host}/home?zbuc=${res.data.customerCode}`
              : `http://${location.host}/home?zbuc=${res.data.customerCode}`
          this.qrCodeUrl = res.data.url
          this.creatQrCode()
        }
      })
    },
    invitationalInfo () {
      invitationalInfo(this.params).then(res => {
        if (res.success) this.tableData = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.invite-DV {
  ::v-deep.el-dialog__body {
    height: 550px;
    overflow-y: scroll;
  }
  .invite-content {
    position: relative;
    min-height: 1365px;
    padding: 440px 0 118px 0;
    background: url('../../../assets/activity/invite-activity-bg.png') no-repeat;
    background-size: 100%;
    .invite-box {
      position: absolute;
      top: 430px;
      left: 50%;
      width: 1080px;
      transform: translate(-50%, 0);
      padding: 0 30px;
      color: #373e4f;
      .invite-title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 28px;
      }
      .invite-rules {
        font-size: 16px;
        line-height: 32px;
        span {
          color: #ffa200;
        }
      }
      .activity-content {
        margin-bottom: 50px;
      }
      .invite-method {
        .qrcode-box {
          display: flex;
          justify-content: center;
          .qrcode {
            border: 1px solid #7443ff;
            padding: 5px;
            margin: 30px auto;
          }
        }
        .tap-clone {
          margin-left: 8px;
          cursor: pointer;
          color: #0063fa;
        }
        .table-box {
          margin-top: 35px;
          img {
            width: 66px;
            height: 66px;
          }
        }

        ::v-deep.el-table__header {
          .cell {
            color: #373e4f;
          }
        }
      }
    }
  }
  ::v-deep.el-dialog__body {
    padding: 0;
  }
}
</style>
