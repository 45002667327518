<template>
  <div class="tips-guide-wrap" v-if="isShow">
    <div class="guide-bubble-wrap" :style="style">
      <img :src="guide.imgUrl" />
      <div class="guide-btn the-btn-skip" :style="guide.skipBtnStyle" @click="skip()">跳过</div>
      <div v-if="step > 0" class="guide-btn the-btn-prev" :style="guide.prevBtnStyle" @click="prev()">
        上一步
      </div>
      <div class="guide-btn the-btn-next" :style="guide.nextBtnStyle" @click="next()">
        下一步
      </div>
      <div class="guide-btn the-btn-finish" v-if="step === maxStep" :style="guide.finishBtnStyle" @click="finish()">
        完成
      </div>
    </div>
  </div>
</template>
<script>
import guide1 from '@/assets/guide/1.png'
import guide2 from '@/assets/guide/2.png'
import guide3 from '@/assets/guide/3.png'
import guide4 from '@/assets/guide/4.png'
import guide5 from '@/assets/guide/5.png'
import guide6 from '@/assets/guide/6.png'

export default {
  name: 'TipsGuide',  // 引导图
  data() {
    return {
      isShow: false,
      step: '',
      minStep: 0,
      maxStep: null,
      guideList: [
        {
          step: 0,
          imgUrl: guide1,
          positionTarget: '#mainSearchWarp',
          nextBtnStyle: 'bottom: 113px; right: 65px;',
          prevBtnStyle: 'display: none;',
          skipBtnStyle: 'bottom: -7px; right: 266px;',
        },
        {
          step: 1,
          imgUrl: guide2,
          offsetTop: -314,
          positionTarget: '#mainTableWarp',
          nextBtnStyle: 'top: 190px; right: 91px;',
          prevBtnStyle: 'top: 190px; right: 241px;',
          skipBtnStyle: 'top: -10px; right: 212px;',
        },
        {
          step: 2,
          imgUrl: guide3,
          offsetTop: -314,
          offsetLeft: 523,
          positionTarget: '#mainTableWarp',
          nextBtnStyle: 'top: 190px; right: 195px;',
          prevBtnStyle: 'top: 190px; right: 345px;',
          skipBtnStyle: 'top: -10px; right: 316px;',
        },
        {
          step: 3,
          imgUrl: guide4,
          offsetTop: -264,
          offsetLeft: 300,
          positionTarget: '#mainSearchWarp',
          nextBtnStyle: 'top: 116px; right: 40px;',
          prevBtnStyle: 'top: 116px; right: 191px;',
          skipBtnStyle: 'top: 244px; right: 162px;',
        },
        {
          step: 4,
          imgUrl: guide5,
          positionTarget: '#pivotWrap',
          style: 'position: fixed; top: 131px; right: 7px;',
          nextBtnStyle: 'top: 116px; right: 295px;',
          prevBtnStyle: 'top: 116px; right: 444px;',
          skipBtnStyle: 'top: 244px; right: 417px;',
        },
        {
          step: 5,
          imgUrl: guide6,
          positionTarget: '#mainTableWarp',
          // nextBtnStyle: 'top: 139px; right: 122px;',
          prevBtnStyle: 'top: 139px; right: 272px;',
          skipBtnStyle: 'display: none;',
          finishBtnStyle: 'top: 139px; right: 122px;',
        },
      ],
      style: '',
    }
  },
  computed: {
    guide() {
      const { step, guideList } = this
      return guideList[step] || ''
    },
  },
  mounted() {
    this.maxStep = this.guideList.length - 1
    setTimeout(() => {
      this.$nextTick(() => {
        this.isShow = true
        this.step = 0
        // this.guide = this.guideList[1]
        this.resetPos()
        window.addEventListener('resize', this.resetPos)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', this.resetPos)
        })
      })
    })
  },
  methods: {
    next() {
      this.step = this.step + 1
      this.$nextTick(() => {
        this.resetPos()
      })
    },
    prev() {
      this.step = this.step - 1
      this.$nextTick(() => {
        this.resetPos()
      })
    },
    skip() {
      this.isShow = false
    },
    finish() {
      this.isShow = false
    },
    resetPos() {
      const { guide } = this
      const {
        positionTarget,
        offsetLeft = 0,
        style
      } = guide
      const target = document.querySelector(positionTarget)
      if (style) {
        this.style = style
        return
      }
      if (target) {
        const top = target.offsetTop + 'px'
        const left = target.offsetLeft + offsetLeft + 'px'
        this.style = `top: ${top};left: ${left}`
      } else {
        this.style = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tips-guide-wrap {
  width: 100%;
  height: 100%;
  position: absolute; // fixed absolute
  top: 0;
  right: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, .6);

  .guide-bubble-wrap {
    position: absolute;
    // width: 500px;
    // height: 172px;
    // border-radius: 8px;
    // background: linear-gradient(0deg, #BCD6FF, #DCEAFF);
    transition: all .6s;
  }

  .guide-btn {
    position: absolute;
    width: 120px;
    height: 36px;
    line-height: 36px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: all .6s;
    opacity: 0;

    &.the-btn-skip {
      width: 40px;
      background: yellowgreen;
    }

    &.the-btn-next {
      background: red;
      border-radius: 20px;
    }

    &.the-btn-prev {
      background: blue;
      border-radius: 20px;
    }

    &.the-btn-finish {
      background: #cc10ee;
      border-radius: 20px;
    }
  }
}
</style>