// 活动相关模块
import request from '@/utils/request'

// 扫码登录
export const getCustomerTicket = (data) => {
  return request({
    method: 'POST',
    url: '​​/api/user/getCustomerTicket',
    data
  })
}

export const invitationalInfo = (data) => {
  return request({
    method: 'POST',
    url: '​​/api/user/invitationalInfo',
    data
  })
}